<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <ImagePreview class="col-auto" v-for="file in files" :image="file" :key="file.code" @remove="removeFile" @replace="replaceFile" />
      <Uploader ref="uploader" class="col-auto" :max="maxFiles" v-model="files" :uploadApiEndpoint="startUpload" :allowedFiles="['image/*']">
        Seleziona immagine
      </Uploader>
    </div>

    <div class="row">
      <FormInputTextWidget
        class="col-lg-12"
        label="Descrizione"
        v-model="note.description"
        :errorMsg="errorHash.description"
        @input="removeFieldError('description')"
      />
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <button class="btn btn-primary px-5">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate';
import uploadFilesMixin from '@/libs/Uploader/mixins/uploadFiles';

export default {
  name: 'note-form',
  mixins: [uploadFilesMixin, validateMixin],
  components: {
    FormContainer: () => import('@/views/components/Form/FormContainer'),
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert'),
    FormInputTextWidget: () => import('@/libs/Form/components/Inputs/Widgets/FormInputTextWidget'),
    Uploader: () => import('@/libs/Uploader/components/Uploader'),
    ImagePreview: () => import('@/libs/Uploader/components/ImagePreview'),
  },
  props: {
    note: {
      type: Object,
      default: () => ({
        description: null,
        images: [],
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      files: this.note?.images ? [...this.note.images] : [],
    };
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.note.description) {
        this.addError('Inserire la descrizione della nota', 'description');
      }

      return !this.hasErrors;
    },
    submit () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.isValid()) {
        return;
      }

      if (!this.uploadsFinishedAndReadyToSubmit()) {
        this.addError('images', 'Caricamento immagini in corso');

        return;
      }

      const noteToReturn = { ...this.note };
      noteToReturn.images = this.filesToSubmit.length > 0 ? [...this.filesToSubmit] : [];

      this.$emit('submitForm', noteToReturn);
    },
  },
  watch: {
    'note.images': function (newValue) {
      this.files = newValue ? [...newValue] : [];
    },
  },
};

</script>
